import React, { forwardRef } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import MaterialTable from "material-table";
import { BsBoxArrowInRight, BsTools } from "react-icons/bs";
import axios from "axios";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useToasts } from "react-toast-notifications";
import { useHistory, Link } from "react-router-dom";
import { BASE_URL } from "../../config/index";
const api = axios.create({
  baseURL: `${BASE_URL}/propertyAccount`,
});
const PropertyAssetIndexPage = () => {
  const { addToast } = useToasts();
  const [loading, setLoading] = React.useState(false);
  const [campaigns, setCampaign] = React.useState([]);
  const [error, setError] = React.useState(null);
  const cancelToken = React.useRef(null);
  const profileValue = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const getData = async () => {
    try {
      if (!profileValue) {
        history.replace("/login");
      }
      setLoading(true);
      const urlPath = `/`;
      const resp = await api.get(urlPath, {
        cancelToken: cancelToken.current.token,
        headers: {
          Authorization: "Bearer " + profileValue.access_token,
        },
      });
      setCampaign(resp.data);
    } catch (err) {
      if (err.response?.status === 401) {
        history.replace("/login");
      }
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const handleRowUpdate = (newData, oldData, resolve) => {
    api
      .put(
        `/${oldData.uid}`,
        {
          totalLoan: newData.totalLoan,
          principle: newData.principle,
          period: newData.period,
          interestRate: newData.interestRate,
          cusoffDate: newData.cusoffDate,
        },
        {
          headers: {
            Authorization: "Bearer " + profileValue.access_token,
          },
        }
      )
      .then((res) => {
        addToast(res.data.message, { appearance: "success" });
        getData();
      })
      .catch((err) => {
        setError(err.message);
        if (err.response) {
          setError(err.response.data.message);
        } else if (err.request) {
          setError(err.request);
        }
        resolve();
      });
  };
  const handleRowDelete = (oldData) => {
    const { uid } = oldData;
    api
      .delete(`/${uid}`, {
        headers: {
          Authorization: "Bearer " + profileValue.access_token,
        },
      })
      .then((res) => {
        addToast(res.data.message, { appearance: "success" });
        getData();
      })
      .catch((err) => {
        setError(err.message);
        if (err.response) {
          setError(err.response.data.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  };

  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getData();
    return () => {
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center mt-5">
        <p>Try Again</p>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col className="text-right mb-2">
            <Button
              variant="info"
              onClick={() => history.push("/propertyAsset/create")}
            >
              Create New propertyAsset
            </Button>{" "}
          </Col>
        </Row>
        <Row>
          <Col>
            <MaterialTable
              icons={tableIcons}
              title="propertyAsset"
              columns={[
                { title: "id", field: "_id", editable: "never", hidden: true },
                {
                  title: "firstName",
                  field: "customer.firstName",
                  editable: "never",
                },
                {
                  title: "firstName",
                  field: "customer.lastName",
                  editable: "never",
                },
                {
                  title: "totalLoan",
                  field: "totalLoan",
                  type: "numeric",
                },
                {
                  title: "คงเหลือ",
                  field: "principle",
                  type: "numeric",
                },
                {
                  title: "งดคงเหลือ",
                  field: "period",
                  type: "numeric",
                },
                {
                  title: "ดอกเบี้ย",
                  field: "interestRate",
                  type: "numeric",
                },
                {
                  title: "วัดตัดรอบ",
                  field: "cusoffDate",
                  type: "numeric",
                },
                {
                  title: "วันจ่ายล่าสุด",
                  field: "lastPayment",
                  editable: "never",
                },
                {
                  title: "จ่ายรอบถัดไป",
                  field: "nextPay",
                  editable: "never",
                },
                {
                  title: "Tools",
                  field: "_id",
                  render: (rowData) =>
                    rowData && (
                      <>
                        <Button
                          variant="outline-success"
                          onClick={() => {
                            window.open(
                              rowData.contactLetterDocumentKey,
                              "_blank"
                            );
                          }}
                        >
                          Contact Letter <BsBoxArrowInRight />
                        </Button>{" "}
                        <Button
                          variant="outline-success"
                          onClick={() => {
                            window.open(
                              rowData.tableInstallmentsDocumentKey,
                              "_blank"
                            );
                          }}
                        >
                          Table Installments <BsBoxArrowInRight />
                        </Button>{" "}
                      </>
                    ),
                },
                {
                  title: "Tools",
                  field: "_id",
                  editable: "never",
                  render: (rowData) =>
                    rowData && (
                      <>
                        <Link to={`/propertyAsset/edit/${rowData.uid}`}>
                          <Button variant="outline-warning">
                            <BsTools />
                          </Button>{" "}
                        </Link>
                      </>
                    ),
                },
              ]}
              data={campaigns}
              options={{
                filtering: true,
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowDelete(oldData);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    handleRowUpdate(newData, oldData, resolve);
                  }),
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PropertyAssetIndexPage;
