import React, { forwardRef } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Image,
  Form,
} from "react-bootstrap";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import { BsBoxArrowInRight } from "react-icons/bs";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../config/index";
const api = axios.create({
  baseURL: `${BASE_URL}/notification`,
});

const PaymentIndex = () => {
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = React.useState(false);
  const [img, setImg] = React.useState("");
  const [amount, setAmout] = React.useState("");
  const [dueDate, setDuedate] = React.useState("");
  const [uid, setUid] = React.useState("");
  const [paymentSendDate, setPaymentSendDate] = React.useState("");
  const [interate, setInterate] = React.useState("");
  const [statusPayment, setStatusPayment] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [campaigns, setCampaign] = React.useState([]);
  const [error, setError] = React.useState(null);
  const cancelToken = React.useRef(null);
  const profileValue = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const getData = async () => {
    try {
      if (!profileValue) {
        history.replace("/login");
      }
      setLoading(true);
      const urlPath = `/`;
      const resp = await api.get(urlPath, {
        cancelToken: cancelToken.current.token,
        headers: {
          Authorization: "Bearer " + profileValue.access_token,
        },
      });
      setCampaign(resp.data);
    } catch (err) {
      if (err.response?.status === 401) {
        history.replace("/login");
      }
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const updateData = async (paymentStatus, paymentAmount) => {
    try {
      api
        .put(
          `/${uid}`,
          {
            paymentStatus,
            paymentAmount,
          },
          {
            headers: {
              Authorization: "Bearer " + profileValue.access_token,
            },
          }
        )
        .then(() => {
          setUid("");
          MySwal.fire("Saved!", "", "success");
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: error?.response?.data?.error?.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: error.response.data.error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      getData();
    }
  };
  const handleClose = () => {
    setShow(false);
    setImg("");
  };

  const reCheck = () => {
    handleClose();
    const paymentAmount = document.getElementById("paymentAmount").value;
    const paymentStatus = document.getElementById("paymentStatusConfirm").value;
    MySwal.fire({
      icon: "question",
      title: "Sure ?",
      html: `
       <div>
          <p>จำนวนเงินที่จ่ายมา : ${paymentAmount}</p>
          <p>สถานนะ : ${paymentStatus === "paid" ? "confirm" : "void"}</p>
       </div>
       
      `,
      showConfirmButton: true,
      showDenyButton: true,
      focusConfirm: false,
      denyButtonText: `Don't save`,
      confirmButtonText: "Save",
    }).then((result) => {
      if (result.isConfirmed) {
        updateData(paymentStatus, paymentAmount);
      } else if (result.isDenied) {
        MySwal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handleShow = () => setShow(true);

  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getData();
    return () => {
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center mt-5">
        <p>Try Again</p>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Container className="mt-4">
            <Image src={img} className="img-fluid" />
          </Container>
          <Container className="mt-4">
            <Form.Group>
              <Form.Label>ที่ต้องจ่าย</Form.Label>
              <Form.Control
                type="text"
                placeholder="Normal text"
                value={amount}
                readOnly
              />
              <br />
              <Form.Label>ดอกเบี้ย</Form.Label>
              <Form.Control
                type="text"
                placeholder="Normal text"
                value={interate}
                readOnly
              />
              <br />
              <Form.Label>วันครบกำหนด</Form.Label>
              <Form.Control
                type="text"
                placeholder="Normal text"
                defaultValue={dueDate}
                readOnly
              />
              <br />
              <Form.Label>วันที่ชำระ</Form.Label>
              <Form.Control
                type="text"
                placeholder="Normal text"
                defaultValue={paymentSendDate}
                readOnly
              />
              <br />
              <Form.Label>เงินที่จ่ายมา</Form.Label>
              <Form.Control
                type="text"
                id="paymentAmount"
                placeholder="Normal text"
                defaultValue={amount}
              />
              <br />
              <Form.Control
                as="select"
                className="my-1 mr-sm-2"
                id="paymentStatusConfirm"
                custom
              >
                <option value="paid">confim</option>
                <option value="void">void</option>
              </Form.Control>
            </Form.Group>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {statusPayment === "wait confirm" && (
            <>
              <Button variant="primary" onClick={reCheck}>
                Save changes
              </Button>
            </>
          )}{" "}
        </Modal.Footer>
      </Modal>
      ;
      <Container className="mt-4">
        {/* <Row>
          <Col className="text-right mb-2">
            <Button
              variant="info"
              onClick={() => history.push("/propertyAsset/create")}
            >
              Create New propertyAsset
            </Button>{" "}
          </Col>
        </Row> */}
        <Row>
          <Col>
            <MaterialTable
              icons={tableIcons}
              title="Confirm Payment"
              columns={[
                { title: "id", field: "_id", editable: "never", hidden: true },
                {
                  title: "Name",
                  field: "customer.fullName",
                },
                {
                  title: "expectAmount",
                  field: "expectAmount",
                  type: "numeric",
                },
                {
                  title: "Payment Status",
                  field: "statusPayment",
                },
                {
                  title: "Payment Date",
                  field: "createdAt",
                },
                {
                  title: "Tools",
                  field: "_id",
                  render: (rowData) =>
                    rowData && (
                      <>
                        <Button
                          variant="outline-success"
                          onClick={() => {
                            setImg(rowData.slipS3Sign);
                            setAmout(rowData.expectAmount);
                            setInterate(rowData.interate);
                            setDuedate(rowData.dueDate);
                            setPaymentSendDate(rowData.dateOfSent);
                            setUid(rowData.uid);
                            setStatusPayment(rowData.statusPayment);
                            handleShow();
                          }}
                        >
                          Slip <BsBoxArrowInRight />
                        </Button>{" "}
                      </>
                    ),
                },
              ]}
              data={campaigns}
              options={{
                filtering: true,
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentIndex;
