import React from "react";
import { Container, Row, Col, Spinner, Button, Form } from "react-bootstrap";
import axios from "axios";
import { BsFillPlusSquareFill } from "react-icons/bs";
import Select from "react-select";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../config/index";
const schema = yup.object().shape({
  totalLoan: yup.string().required("กรุณาใส่ยอดเงินทั้งหมด"),
  principle: yup.string().required("กรุณาใส่ยอดเงินคงเหลือ"),
  cusoffDate: yup
    .number()
    .typeError("cusoffDate must be a number")
    .positive("จำนวนบวกเท่านั้น")
    .min(1)
    .max(31),
  period: yup
    .number()
    .typeError("period must be a number")
    .required("กรุณาใส่จำนวนเดือน")
    .positive("จำนวนบวกเท่านั้น"),
  paymentEachMonth: yup.string().required("กรุณาใส่ยอดเงินคงเหลือ"),
  totalMonth: yup
    .number()
    .typeError("totalMonth must be a number")
    .required("กรุณาใส่จำนวนเดือน")
    .positive("จำนวนบวกเท่านั้น"),
});
const api = axios.create({
  baseURL: `${BASE_URL}/`,
});

const PropertyAssetEditPage = () => {
  const { id } = useParams();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = React.useState(false);
  const [customerFocus, setCustomerFocus] = React.useState(false);
  const [customers, setCustomer] = React.useState([]);
  const [asset, setAsset] = React.useState([]);
  const [contactLetterDocument, setContactLetterDocument] =
    React.useState(null);
  const [tableInstallmentsDocument, setTableInstallmentsDocument] =
    React.useState(null);
  const [error, setError] = React.useState(null);
  const [totalLoan, setTotalLoan] = React.useState(null);
  const [principle, setPrinciple] = React.useState(null);
  const [paymentEachMonth, setPaymentEachMonth] = React.useState(null);
  const [customerSelect, setCustomerSelect] = React.useState(null);
  const cancelToken = React.useRef(null);
  const profileValue = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      if (contactLetterDocument) {
        formData.append("contactLetterDocument", contactLetterDocument);
      }
      if (tableInstallmentsDocument) {
        formData.append("tableInstallmentsDocument", tableInstallmentsDocument);
      }
      formData.append(
        "totalLoan",
        parseFloat(data.totalLoan.replace(/,/g, ""))
      );
      formData.append("customerId", customerSelect);
      formData.append(
        "principle",
        parseFloat(data.principle.replace(/,/g, ""))
      );
      formData.append(
        "paymentEachMonth",
        parseFloat(data.paymentEachMonth.replace(/,/g, ""))
      );
      formData.append("propertyName", data.propertyName);
      formData.append("period", data.period);
      formData.append("totalMonth", data.totalMonth);
      formData.append("interestRate", data.interestRate);
      formData.append("cusoffDate", data.cusoffDate);
      await api.patch(`propertyAccount/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + profileValue.access_token,
        },
      });
      MySwal.fire({
        icon: "success",
        title: "Update propertyAsset Success",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.replace("/propertyAsset");
      });
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: error.response.data.error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const getDataOfAsseet = async () => {
    try {
      if (!profileValue) {
        history.replace("/login");
      }
      setLoading(true);
      const urlPath = `/propertyAccount/${id}`;
      const resp = await api.get(urlPath, {
        cancelToken: cancelToken.current.token,
        headers: {
          Authorization: "Bearer " + profileValue.access_token,
        },
      });
      setTotalLoan(resp.data.totalLoan);
      setPrinciple(resp.data.principle);
      setPaymentEachMonth(resp.data.paymentEachMonth);
      setCustomerSelect(resp.data.customerId);
      setAsset(resp.data);
    } catch (err) {
      if (err.response?.status === 401) {
        history.replace("/login");
      }
      setError(err.message);
    } finally {
      getData();
      setLoading(false);
    }
  };
  const getData = async () => {
    try {
      if (!profileValue) {
        history.replace("/login");
      }
      setLoading(true);
      const urlPath = `/customer/getFullName`;
      const resp = await api.get(urlPath, {
        cancelToken: cancelToken.current.token,
        headers: {
          Authorization: "Bearer " + profileValue.access_token,
        },
      });
      setCustomer(resp.data);
    } catch (err) {
      if (err.response?.status === 401) {
        history.replace("/login");
      }
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const addCommar = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setTotalLoan(value);
  };

  const handleChange = (selectedOption) => {
    setCustomerSelect(selectedOption.value);
  };

  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getDataOfAsseet();

    return () => {
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center mt-5">
        <p>Try Again</p>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col>
            <Button
              variant="dark"
              onClick={() => history.push("/propertyAsset/")}
            >
              Back to propertyAsset
            </Button>{" "}
          </Col>
        </Row>
      </Container>
      <Container className="mt-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridPropertyName">
                <Form.Label>property name</Form.Label>
                <Form.Control
                  placeholder="property name"
                  type="text"
                  defaultValue={asset?.propertyName}
                  name="propertyName"
                  ref={register}
                  className={`form-control ${
                    errors.propertyName ? "is-invalid" : ""
                  }`}
                />
                {errors.propertyName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.propertyName.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridCusoffDate">
                <Form.Label>ลูกค้า</Form.Label>
                <Select
                  defaultValue={{
                    label: asset?.customer?.fullName,
                    value: asset?.customerId,
                  }}
                  options={customers}
                  onChange={handleChange}
                  isSearchable={true}
                  autoFocus={customerFocus}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridCusoffDate">
                <Form.Label>วันตัดรอบ</Form.Label>
                <Form.Control
                  placeholder="วันตัดรอบ"
                  type="number"
                  name="cusoffDate"
                  ref={register}
                  defaultValue={asset?.cusoffDate}
                  className={`form-control ${
                    errors.cusoffDate ? "is-invalid" : ""
                  }`}
                />
                {errors.cusoffDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.cusoffDate.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridTotalLoan">
                <Form.Label>ยอดเงินทั้งหมด</Form.Label>
                <Form.Control
                  placeholder="ยอดเงินทั้งหมด"
                  type="text"
                  name="totalLoan"
                  ref={register}
                  value={totalLoan}
                  onChange={addCommar}
                  className={`form-control ${
                    errors.totalLoan ? "is-invalid" : ""
                  }`}
                />
                {errors.totalLoan && (
                  <Form.Control.Feedback type="invalid">
                    {errors.totalLoan.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridPrinciple">
                <Form.Label>ยอดเงินคงเหลือ</Form.Label>
                <Form.Control
                  placeholder="ยอดเงินคงเหลือ"
                  type="text"
                  name="principle"
                  ref={register}
                  value={principle}
                  defaultValue={asset?.principle}
                  onChange={(event) => {
                    let value = event.target.value;
                    value = value
                      .replace(/\D/g, "")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    setPrinciple(value);
                  }}
                  className={`form-control ${
                    errors.principle ? "is-invalid" : ""
                  }`}
                />
                {errors.totalLoan && (
                  <Form.Control.Feedback type="invalid">
                    {errors.principle.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridInterestRate">
                <Form.Label>อัตราดอกเบี้ย</Form.Label>
                <Form.Control
                  placeholder="อัตราดอกเบี้ย EX. 3.30"
                  type="number"
                  step=".01"
                  name="interestRate"
                  defaultValue={asset?.interestRate}
                  ref={register}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridPaymentEachMonth">
                <Form.Label>จำนวนชำระแต่ละเดือน</Form.Label>
                <Form.Control
                  placeholder="จำนวนชำระแต่ละเดือน"
                  type="text"
                  name="paymentEachMonth"
                  value={paymentEachMonth}
                  onChange={(event) => {
                    let value = event.target.value;
                    value = value
                      .replace(/\D/g, "")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    setPaymentEachMonth(value);
                  }}
                  ref={register}
                  className={`form-control ${
                    errors.period ? "is-invalid" : ""
                  }`}
                />
                {errors.paymentEachMonth && (
                  <Form.Control.Feedback type="invalid">
                    {errors.paymentEachMonth.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridTotalMonth">
                <Form.Label>จำนวนเดือนทั้งหมด</Form.Label>
                <Form.Control
                  placeholder="จำนวนเดือนทั้งหมด"
                  type="number"
                  name="totalMonth"
                  ref={register}
                  defaultValue={asset?.periodTotal}
                  className={`form-control ${
                    errors.totalMonth ? "is-invalid" : ""
                  }`}
                />
                {errors.totalMonth && (
                  <Form.Control.Feedback type="invalid">
                    {errors.totalMonth.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridPeriod">
                <Form.Label>จำนวนเดือนคงเหลือ</Form.Label>
                <Form.Control
                  placeholder="จำนวนเดือน"
                  type="number"
                  name="period"
                  ref={register}
                  defaultValue={asset?.period}
                  className={`form-control ${
                    errors.period ? "is-invalid" : ""
                  }`}
                />
                {errors.period && (
                  <Form.Control.Feedback type="invalid">
                    {errors.period.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Form.Group as={Col}>
                    <Form.File
                      className="position-relative"
                      name="file"
                      label="หนังสือสัญญา"
                      onChange={(e) =>
                        setContactLetterDocument(e.target.files[0])
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Button
                    variant="outline-success mt-3"
                    onClick={() => {
                      window.open(asset.contactLetterDocumentKey, "_blank");
                    }}
                  >
                    Show File หนังสือสัญญา <BsFillPlusSquareFill />
                  </Button>{" "}
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  {" "}
                  <Form.Group as={Col}>
                    <Form.File
                      className="position-relative"
                      name="file"
                      label="ตารางผ่อน"
                      onChange={(e) =>
                        setTableInstallmentsDocument(e.target.files[0])
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {" "}
                  <Button
                    variant="outline-info mt-3"
                    onClick={() => {
                      window.open(asset.tableInstallmentsDocumentKey, "_blank");
                    }}
                  >
                    Show File ตารางผ่อน <BsFillPlusSquareFill />
                  </Button>{" "}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button variant="primary" className="mt-3" type="submit">
                <BsFillPlusSquareFill className="mr-2" />
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default PropertyAssetEditPage;
