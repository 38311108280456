import React from "react";

import {
  Container,
  Row,
  Col,
  Spinner,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import { BsFillPlusSquareFill } from "react-icons/bs";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../config/index";
import Swal from "sweetalert2";
const api = axios.create({
  baseURL: `${BASE_URL}/notification`,
});
const Confirm = () => {
  const { id } = useParams();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [response, setResponse] = React.useState(null);
  const [confirmPayment, setconfirmPayment] = React.useState(null);
  const cancelToken = React.useRef(null);
  const getData = async () => {
    try {
      setLoading(true);
      const urlPath = `/${id}`;
      const resp = await api.get(urlPath, {
        cancelToken: cancelToken.current.token,
      });
      setResponse(resp.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      if (!confirmPayment) {
        throw new Error("ส่ง slip ด้วยครับ");
      }
      formData.append("confirmPayment", confirmPayment);
      await api.patch(`/${id}`, formData);
      MySwal.fire({
        icon: "success",
        title: "Create propertyAsset Success",
        showConfirmButton: false,
        timer: 1500,
      });
      setResponse("success");
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: error || error?.response?.data?.error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getData();
    return () => {
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center mt-5">
        <p>Try Again</p>
        <p>{error}</p>
      </div>
    );
  }
  if (response === null) {
    return (
      <div className="text-center mt-5">
        <p>ไม่พบข้อมูล</p>
      </div>
    );
  }
  if (response === "success") {
    return (
      <div className="text-center mt-5">
        <p>ขอบคุณสำหรับการชำระเงิน</p>
      </div>
    );
  }
  return (
    <>
      <Container className="mt-4">
        <h2 className="text-center">Confirm Payment</h2>
        <Table responsive>
          {response.expectAmount && (
            <>
              <tr>
                <td>ยอดชำระ</td>
                <td>{response.expectAmount}</td>
              </tr>
            </>
          )}
          {response.dueDate && (
            <>
              <tr>
                <td>วันครบกำหนด</td>
                <td>{response.dueDate}</td>
              </tr>
            </>
          )}
          {response.statusPayment && (
            <>
              <tr>
                <td>สถานะการชำระเงิน</td>
                <td>{response.statusPayment}</td>
              </tr>
            </>
          )}{" "}
          {response.differenceDays && (
            <>
              <tr>
                <td>จำนวนวันที่จะถึงกำหนด</td>
                <td>{response.differenceDays}</td>
              </tr>
            </>
          )}
        </Table>

        {response.statusPayment === "unPaid" && (
          <>
            <Row>
              <Col>
                <Col>
                  <Form.Group as={Col}>
                    <Form.File
                      className="position-relative"
                      name="file"
                      label="slip"
                      required
                      onChange={(e) => setconfirmPayment(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  className="mt-3"
                  type="submit"
                  onClick={onSubmit}
                >
                  <BsFillPlusSquareFill className="mr-2" />
                  Send Confirm Payment
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Confirm;
